import * as AOS from 'aos';

import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {AccessService} from '../../services/access.service';
import {AuthService} from 'src/app/services/auth.service';
import {CodeService} from '../../services/code.service';
import {CodeType} from '../../enum/CodeType';
import {EventForCustomer} from 'src/app/models/events/EventForCustomer';
import {EventService} from 'src/app/services/event.service';
import {EventsCategorized} from 'src/app/models/events/EventsCategorized';
import {TableService} from '../../services/table.service';
import {TicketTypeResponseApp} from '../../models/TicketTypeResponseApp';
import {environment} from 'src/environments/environment';
import {forkJoin} from 'rxjs';
import {BreakpointObserver} from '@angular/cdk/layout';
import {EventShortForCategorizedList} from '../../models/events/EventShortForCategorizedList';
import {User} from 'src/app/models/user';
import {ToastService} from 'src/app/services/toast.service';
import {SpinnerService} from 'src/app/services/spinner.service';
import { PromotionPersist } from 'src/app/models/PromotionPersist';
import { FirebaseRemoteConfigService } from 'src/app/services/firebase-remote-config.service';

@Component({
  selector: 'app-billboard',
  templateUrl: './billboard.component.html',
  styleUrls: ['./billboard.component.scss']
})
export class BillboardComponent implements OnInit {
  mainEvents: EventShortForCategorizedList[] = null;
  skeletonLoading = true;
  environment = environment;
  originalAllCategoriesEvents: Array<EventsCategorized> = [];
  allCategoriesEvents: Array<EventsCategorized> = [];
  loading = true;
  user: User;
  typePage = 'billboard';
  logged = false;
  guestLogged = false;
  textSearch: string = null;
  search = false;
  searchList: Array<EventForCustomer> = null;
  code = null;
  isSmallScreen = false;
  showUnverifiedAccountReminderModal = false;
  invitationType = false;
  showModalActionLogin = false;

  constructor(
    private eventSrv: EventService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private codeSrv: CodeService,
    private tableSrv: TableService,
    private accessSrv: AccessService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private toastService: ToastService,
    private spinnerService: SpinnerService,
    private firebaseRemoteConfigSrv: FirebaseRemoteConfigService
  ) {
    const pathArray = window.location;
    const newUrl = new URL(pathArray.toString());
    const searchText = newUrl.searchParams.get('search');
    if (searchText) {
      this.textSearch = searchText;
    }
    this.route.paramMap.subscribe({
      next: async (params: ParamMap) => {
        const code = params.get('code');
        const myQR = params.get('myQR');
        const idEvent = params.get('id');
        await this.authService.checkUserLogged();
        if (code) {
          this.code = code;
          this.codeSrv.getCodeType(code).subscribe({
            next: (codeType: CodeType) => {
              if (codeType === CodeType.BUY_TABLE_CODE || codeType === CodeType.BUY_ACCESS_CODE) {
                this.codeSrv.validateBuyCode(code, null).subscribe({
                  next: (ticketTypeResponseApp: TicketTypeResponseApp) => {
                    if (ticketTypeResponseApp.tableType) {
                      this.router.navigate(['buy-ticket/' + idEvent + '/T/code/AUT/' + code]);
                    } else if (ticketTypeResponseApp.accessType) {
                      this.router.navigate(['buy-ticket/' + idEvent + '/A/code/AUT/' + code]);
                    }
                  }
                });
              } else if (codeType === CodeType.ACCESS_INVITATION_CODE || CodeType.LIST_INVITATION_CODE || CodeType.TABLE_INVITATION_CODE) {
                if (this.logged) {
                  this.isSmallScreen = this.breakpointObserver.isMatched('(max-width: 768px)');
                  this.eventSrv.showModalEventCode.next({state: true, code: this.code});
                } else {                  
                  this.invitationType = true;
                  this.openModalLogin();
                }
              }
            },
            error: err => {
              if (err.status === 406) {
                this.router.navigate(['/event-not-found']);
              }
            }
          });
        }

        if (myQR && myQR === 'show') {
          const logged = this.authService.isLoggedIn();
          if (!logged) {
            const promotionPersist = new PromotionPersist();
            promotionPersist.showQR = true;
            promotionPersist.eventId = null;
            promotionPersist.promoCode = null;
            sessionStorage.setItem('promotionPersist', JSON.stringify(promotionPersist));
            this.router.navigate(['login']);
          } else {
            this.authService.showModalMyQr.next({state: true});
          }
        }
      }
    });
  }

  async ngOnInit() {
    await this.authService.checkUserLogged();

    const logged = this.authService.isLoggedIn();
    if (logged) {
      this.authService.getCurrentUserData().subscribe({
        next: (user) => {
          this.user = user;
          if (!this.user.emailVerified || !this.user.verifiedData) {
            this.showUnverifiedAccountReminderModal = true;
          }
        },
        error: (err) => {
          console.log(err);
        }
      });
    }

    this.logged = this.authService.isLoggedIn();
    this.guestLogged = this.authService.isGuestLoggedIn();
    if (this.logged || this.guestLogged) {
      this.getEvents(this.logged);
    }
    AOS.init({
      duration: 800,
      once: true
    });
    this.getUnderMaintenance();
  }

  getEvents(logged) {
    this.mainEvents = [];
    this.loading = true;
    if (!logged) {
      this.eventSrv.getAllEventsCategorizedByTenant().subscribe({
        next: (res: EventsCategorized[]) => {
          this.originalAllCategoriesEvents = res;
          this.mainEvents = this.getMainEventsFromResponse(res);
          this.allCategoriesEvents = this.removeMainEventsFromCategorizedEvents(res);
          this.allCategoriesEvents = this.sortCategoryForPosition(this.allCategoriesEvents);
          this.loading = false;
        }
      });
    } else {
      forkJoin({
        pendingEventsIds: this.eventSrv.getEventsIdsByCustomer(),
        allCategoriesEvents: this.eventSrv.getAllEventsCategorizedByTenant(),
      }).subscribe({
        next: (res) => {
          this.originalAllCategoriesEvents = res.allCategoriesEvents;
          this.checkCustomerIsOnBillboardEvent(
            res.allCategoriesEvents,
            res.pendingEventsIds
          );
          this.mainEvents = this.getMainEventsFromResponse(res.allCategoriesEvents);
          this.allCategoriesEvents = this.removeMainEventsFromCategorizedEvents(res.allCategoriesEvents);
          this.allCategoriesEvents = this.sortCategoryForPosition(this.allCategoriesEvents);
          this.loading = false;
        }
      });
    }
  }

  getMainEventsFromResponse(allCategoriesEvents: EventsCategorized[]) {
    const mainEvents: EventShortForCategorizedList[] = [];
    allCategoriesEvents.forEach(cat => {
      cat.eventForCustomerList.forEach(ev => {
        if (ev.mainEvent) {
          mainEvents.push(ev);
        }
      });
    });
    return mainEvents;
  }

  removeMainEventsFromCategorizedEvents(allCategoriesEvents: EventsCategorized[]) {
    const allCategoriesWithEvents: EventsCategorized[] = [];
    allCategoriesEvents.forEach(cat => {
      const category: EventsCategorized = new EventsCategorized();
      category.name = cat.name;
      category.position = cat.position;
      category.idCategory = cat.idCategory;
      category.eventForCustomerList = [];
      cat.eventForCustomerList.forEach(ev => {
        if (!ev.mainEvent) {
          category.eventForCustomerList.push(ev);
        }
      });
      if (category.eventForCustomerList.length > 0) {
        allCategoriesWithEvents.push(category);
      }
    });
    return allCategoriesWithEvents;
  }

  sortCategoryForPosition(allEvents: Array<EventsCategorized>) {
    return allEvents.sort((a: EventsCategorized, b: EventsCategorized) => {
      if (a.position > b.position) {
        return 1;
      }
      if (a.position < b.position) {
        return -1;
      }
      return 0;
    });
  }

  checkCustomerIsOnBillboardEvent(
    allCategoryEvents: Array<EventsCategorized>,
    pendingEvents: Array<number>
  ) {
    allCategoryEvents.forEach((categoryEvents: EventsCategorized) => {
      categoryEvents.eventForCustomerList.forEach((event: EventForCustomer) => {
        const eventExist = pendingEvents.filter(id => id === event.idEvent);
        if (eventExist.length > 0) {
          event.hasAuthorizedAccess = true;
        }
      });
    });
  }

  onActionUnverifiedModalReminder() {
    this.spinnerService.loadSpinner.next(true);
    this.authService.resendEmailToken().subscribe({
      next: () => {
        this.toastService.generateToast('success', $localize`Te enviamos un email de verificación`, $localize`Seguí las instrucciones desde tu casilla de correo, para activar tu cuenta.`);
        this.showUnverifiedAccountReminderModal = false;
        this.spinnerService.loadSpinner.next(false);
      }, error: (err) => {
        this.toastService.generateToast('error', $localize`Error al enviar el email, intentelo nuevamente`, '');
        this.spinnerService.loadSpinner.next(false);
      }
    });
  }

  openModalLogin() {
    this.showModalActionLogin = true;
  }

  onActionToLogin() {
    if (this.code !== null && this.invitationType) {
      const promotionPersist: PromotionPersist = new PromotionPersist();
      promotionPersist.invitationType = true;      
      promotionPersist.promoCode = this.code;
      sessionStorage.setItem('promotionPersist', JSON.stringify(promotionPersist));
    } 
    this.router.navigate(['/login']);
  }
  
  closeModalLogin() {
    this.showModalActionLogin = false;
  }

  getUnderMaintenance() {
    this.firebaseRemoteConfigSrv.getBooleanValueByKey(environment.isUnderMaintenanceFirebaseKey)
    .then(result => {      
        if (result) {
          this.router.navigateByUrl('maintenance');
        }
    });
  }
}
