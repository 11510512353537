import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import { FirebaseRemoteConfigService } from 'src/app/services/firebase-remote-config.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  constructor(
    private router: Router,
    private firebaseRemoteConfigSrv: FirebaseRemoteConfigService
  ) {
  }

  ngOnInit(): void {
    this.getUnderMaintenance();
    // if (!environment.isUnderMaintenance) {
    //   this.router.navigateByUrl('/');
    // }
  }

  getUnderMaintenance() {
    this.firebaseRemoteConfigSrv.getBooleanValueByKey(environment.isUnderMaintenanceFirebaseKey)
    .then(result => {
      //this.isInMaintenance = result;
        if (result) {
          this.router.navigateByUrl('maintenance');
        } else {
          this.router.navigateByUrl('/');
        }
    });
  }

}
