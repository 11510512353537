import {Component, OnInit} from '@angular/core';
import {AuthService} from './services/auth.service';

import {SpinnerService} from './services/spinner.service';
import jwt_decode from 'jwt-decode';
import {TokenRefreshResponse} from './models/TokenRefreshResponse';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {EventService} from './services/event.service';
import {Platform} from '@angular/cdk/platform';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MentaService} from './services/menta.service';
import {FirebaseRemoteConfigService} from './services/firebase-remote-config.service';
import {SupportService} from './services/support.service';
import { ToastService } from './services/toast.service';
import { MessageService } from 'primeng/api';
import { NavbarService } from './services/navbar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})
export class AppComponent implements OnInit {
  showSpinner = false;
  showModalUpdateUserData = false;
  isSmallScreen = false;
  showModalQR = false;
  showModalLanguageMobile = false;
  showMainMenu = false;
  logged: boolean;
  showSupportModal = false;
  isInMaintenance = false;
  showNav = true;

  constructor(
    private spinnerService: SpinnerService,
    private authSrv: AuthService,
    private router: Router,
    private eventSrv: EventService,
    private platform: Platform,
    private breakpointObserver: BreakpointObserver,
    private mentaTicketsSrv: MentaService,
    private firebaseRemoteConfigSrv: FirebaseRemoteConfigService,
    private supportService: SupportService,
    private toastService: ToastService,
    private messageSrv: MessageService,
    private navbarService: NavbarService
  ) {
  }

  async ngOnInit() {

    this.navbarService.showNavbar.subscribe((res: boolean) => {
      this.showNav = res;
    });

    await this.firebaseRemoteConfigSrv.loadConfig();


    this.toastService.showToast.subscribe({
      next: (toast) => {
        this.showToast(toast.severity, toast.summary, toast.detail);
      }
    });

    this.mentaTicketsSrv.initializeMenta();
    // if (environment.isUnderMaintenance) {
    //   this.isInMaintenance = environment.isUnderMaintenance;
    //   await this.router.navigateByUrl('maintenance');
    // } else {
    //   this.isInMaintenance = false;
    // }
    await this.authSrv.checkUserLogged();
    if (JSON.parse(localStorage.getItem('user'))) {
      this.authSrv.getCurrentUserData().subscribe(user => {
        this.authSrv.updateUser(user);
      });
      const jwt = localStorage.getItem('BE-JWT');
      const decoded = jwt_decode(jwt);
      const exp = decoded['exp'];
      if (Date.now() >= exp * 1000) {
        this.authSrv.refreshToken().subscribe({
          next: (newToken: TokenRefreshResponse) => {
            if (newToken) {
              localStorage.setItem('BE-JWT', newToken.token);
              localStorage.setItem('isLoggedIn', 'true');
              sessionStorage.setItem('guestLogged', 'false');
            }
          }
        });
      }
    }
    this.spinnerService.loadSpinner.subscribe({
      next: (currentState: boolean) => {
        this.showSpinner = !!currentState;
      }
    });

    this.supportService.openSupportModal.subscribe(res => {
      this.onOpenSupportModal(res);
    });
  }

  showToast(severity: string, summary: string, detail: string) {
    this.messageSrv.add({
      severity,
      summary,
      detail,
      life: 5000,
      sticky: false,
      closable: true
    });
  }

  async onActionCloseModal(event) {
    await this.router.navigate(['/profile']);
  }

  showModalInvitationCode() {
      this.eventSrv.showModalEventCode.next({state: true}); 
  }

  onOpenSupportModal(e) {
    this.isSmallScreen = this.breakpointObserver.isMatched('(max-width: 768px)');
    if (this.isSmallScreen) {
      this.router.navigate(['support']);
    } else {
      this.showSupportModal = e;
    }
  }

  onCloseModal($event) {
    this.showSupportModal = false;
  }

  showModalMyQr(event) {
    this.showModalQR = true;
    this.authSrv.showModalMyQr.next({state: true});
  }

  displayMainMenu(event) {
    this.showMainMenu = event;
    this.logged = !!JSON.parse(localStorage.getItem('isLoggedIn'));
    this.authSrv.showMainMenu.next({state: event, logged: this.logged});
  }

  showModalLanguage(event) {
    this.showModalLanguageMobile = true;
    this.authSrv.showModalLanguage.next({state: true});
  }

  onModalActionPrimaryButton(key: string) {
    if (key === 'no-event-code') {
      this.showModalInvitationCode();
    }
  }

  onModalActionSecondaryButton(key: string) {
  }
}
