import * as AOS from 'aos';

import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';

import {AccessRequest} from 'src/app/models/AccessRequest';
import {AccessService} from 'src/app/services/access.service';
import {AuthService} from 'src/app/services/auth.service';
import {CheckoutResponse} from 'src/app/models/CheckoutResponse';
import {EventForCustomer} from 'src/app/models/events/EventForCustomer';
import {EventService} from 'src/app/services/event.service';
import {Location} from '@angular/common';
import {PaymentService} from 'src/app/services/payment.service';
import {Promotion} from 'src/app/models/Promotion';
import {SpinnerService} from 'src/app/services/spinner.service';
import {TableRequest} from 'src/app/models/TableRequest';
import {TableService} from 'src/app/services/table.service';
import {VerificationAccount} from 'src/app/models/VerificationAccount';
import {environment} from 'src/environments/environment';
import {PromotionPersist} from '../../../../../models/PromotionPersist';
import {PaymentProvider} from '../../../../../enum/PaymentProvider';
import {ModalActionComponent} from '../../../../../share/components/modal-action/modal-action.component';
import {ModalController} from '../../../../../controller/modal-controller';
import { FirebaseRemoteConfigService } from 'src/app/services/firebase-remote-config.service';

@Component({
  selector: 'app-checkout-ticket',
  templateUrl: './checkout-ticket.component.html',
  styleUrls: ['./checkout-ticket.component.scss']
})
export class CheckoutTicketComponent implements OnInit {

  data: any;
  eventData: EventForCustomer;
  showImage = false;
  showModalActionLogin = false;
  currentPayment: AccessRequest = null;
  currentPaymentTable: TableRequest = null;
  code = '';
  imageEvent: string;
  type = 'T';
  promoDiscount: number = null;
  skeletonLoading = true;
  showErrorModal = false;
  errorTitle = '';
  errorContent = '';
  showNeedVerifiedAccount = false;
  errorAccountTitle = '';
  errorAccountContent = '';
  errorAccountIcon = '';
  errorAccountImage = '';
  environment = environment;
  idEvent = null;
  typeBuy = null;
  errorIcon: string = null;
  dateFormat = $localize`dd MMM yyyy`;
  timeFormat = $localize`HH:mm`;
  currency = '$';
  promoCodeValid: boolean;
  promotionPersist: PromotionPersist = null;

  showRedirectPaymentModal = false;
  modalRedirectPaymentTitle = null;
  firstToMobbexModal = $localize`Ir a pagar`;
  secondToMobbexModal = $localize`Ahora no`;
  imagePlatformName = null;


  constructor(
    private router: Router,
    private location: Location,
    private accessSrv: AccessService,
    private tableSrv: TableService,
    private eventService: EventService,
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    private firebaseRemoteConfigSrv: FirebaseRemoteConfigService
  ) {
    this.route.paramMap.subscribe({
      next: (params: ParamMap) => {
        const id = params.get('id');
        const type = params.get('type');
        const code = params.get('code');
        if (id) {
          this.idEvent = id;
        }
        if (type) {
          this.typeBuy = type;
        }
        if (code) {
          this.code = code;
        }
      }
    });
  }

  ngOnInit(): void {
    this.scrollToTop();
    const existPromotionPersist = sessionStorage.getItem('promotionPersist');
    if (existPromotionPersist) {
      this.promotionPersist = JSON.parse(sessionStorage.getItem('promotionPersist'));
      if (this.idEvent === this.promotionPersist.eventId && this.promotionPersist.promoCode !== null) {
        this.code = this.promotionPersist.promoCode;
      }
    }
    this.spinnerService.loadSpinner.next(false);
    this.eventService.getEventById(this.idEvent).subscribe({
      next: (event: EventForCustomer) => {
        this.eventData = event;
        this.currency = event.storeDataOnEvent.currency;
        if (event.soldOut) {
          this.router.navigate(['billboard']);
        }
        this.imageEvent = environment.mediaUrl + this.eventData.image;
        if (this.typeBuy === 'A') {
          this.accessSrv.currentPayment$.subscribe({
            next: (accessRequest: AccessRequest) => {
              this.currentPayment = accessRequest;
              if (this.currentPayment === null || Object.keys(this.currentPayment).length === 0) {
                this.checkCodeAndNavigate();
              }
            }
          });
        } else if (this.typeBuy === 'T') {
          this.tableSrv.currentPaymentTable$.subscribe({
            next: (tableRequest: TableRequest) => {
              this.currentPaymentTable = tableRequest;
              if (this.currentPaymentTable === null || Object.keys(this.currentPaymentTable).length === 0) {
                this.checkCodeAndNavigate();
              }
            }
          });
        }
        this.skeletonLoading = false;
      },
      error: (err) => {
        if (err.status === 404) {
          this.router.navigate(['/event-not-found']);
        }
      }
    });
    AOS.init({
      duration: 800,
    });
    this.getUnderMaintenance();
  }

  private checkCodeAndNavigate() {
    if (this.code !== null && this.code !== '') {
      this.router.navigate(['/buy-ticket/' + this.idEvent + '/' + this.typeBuy + '/code/' + this.code]);
    } else {
      this.router.navigate(['/buy-ticket/' + this.idEvent + '/' + this.typeBuy]);
    }
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
  }

  onClosePaymentModal() {
    this.showRedirectPaymentModal = false;
  }

  onGoToPaymentModal() {
    this.showRedirectPaymentModal = false;
    this.payTicket();
  }

  verifyUserData() {
    this.spinnerService.loadSpinner.next(true);
    const logged = this.authService.isLoggedIn();
    if (logged) {
      this.authService.getAccountStatus().subscribe({
        next: async (res: VerificationAccount) => {
          if (!res.documentVerified || !res.emailVerified || res.blocked) {
            await this.handleAccountStatus(res);
          } else if (res.documentVerified && res.emailVerified && !res.blocked) {
            this.spinnerService.loadSpinner.next(false);
            if ((this.currentPayment !== null && this.currentPayment?.free)
              || (this.currentPaymentTable !== null && this.currentPaymentTable?.free)) {
              this.payTicket();
            } else {
              let modalTitle;
              if (this.eventData.storeDataOnEvent.webPaymentProvider === PaymentProvider.MERCADOPAGO) {
                this.imagePlatformName = 'mp.svg';
                modalTitle = $localize`Serás redirigido a la plataforma de Mercado Pago para finalizar tu compra.`;
              } else if (this.eventData.storeDataOnEvent.webPaymentProvider === PaymentProvider.MOBBEX) {
                this.imagePlatformName = 'mobbex.png';
                modalTitle = $localize`Serás redirigido a la plataforma de Mobbex para finalizar tu compra.`;
              } else if (this.eventData.storeDataOnEvent.webPaymentProvider === PaymentProvider.STRIPE) {
                this.imagePlatformName = 'stripe.png';
                modalTitle = $localize`Serás redirigido a la plataforma de Stripe para finalizar tu compra.`;
              } else {
                this.imagePlatformName = null;
                modalTitle = $localize`Serás redirigido a la plataforma de pago para finalizar tu compra.`;
              }
              this.modalRedirectPaymentTitle = modalTitle;
              this.showRedirectPaymentModal = true;
            }
          }
        },
        error: (err) => {
          this.handleErrorAccountStatus(err);
        }
      });
    } else {
      if (this.code !== null && this.code !== '') {
        this.router.navigate(['/detail-event/' + this.idEvent + '/code/' + this.code]);
      } else {
        this.router.navigate(['/detail-event/' + this.idEvent]);
      }
    }
  }

  payTicket() {
    const logged = this.authService.isLoggedIn();
    const guestLogged = this.authService.isGuestLoggedIn();

    if (!logged || guestLogged) {
      this.spinnerService.loadSpinner.next(false);
      this.showModalActionLogin = true;
    } else {
      localStorage.setItem('eventId', this.idEvent);
      if (this.typeBuy === 'A') {
        this.accessSrv.generateCheckout(this.currentPayment).subscribe({
          next: (checkoutResponseAccess: CheckoutResponse) => {
            localStorage.setItem('typeBuy', this.typeBuy);
            localStorage.setItem('promoDiscount', JSON.stringify(this.promoDiscount));
            if (this.currentPayment?.free) {
              localStorage.setItem('typeBuy', this.typeBuy);
              this.spinnerService.loadSpinner.next(false);
              sessionStorage.removeItem('promotionPersist');
              localStorage.removeItem('lastDetailEvent');
              this.router.navigate(['/success-purchase']);
            }
            if (checkoutResponseAccess) {
              localStorage.setItem('typeBuy', this.typeBuy);
              this.spinnerService.loadSpinner.next(false);
              window.location.href = checkoutResponseAccess.url;
            }
          },
          error: (err) => {
            this.handleErrorCheckoutAccess(err);
          }
        });
      } else if (this.typeBuy === 'T') {
        localStorage.setItem('promoDiscount', JSON.stringify(this.promoDiscount));
        this.tableSrv.generateCheckoutTable(this.currentPaymentTable).subscribe({
          next: (checkoutResponseTable: CheckoutResponse) => {
            if (this.currentPaymentTable?.free) {
              localStorage.setItem('typeBuy', this.typeBuy);
              sessionStorage.removeItem('promotionPersist');
              localStorage.removeItem('lastDetailEvent');
              this.spinnerService.loadSpinner.next(false);
              this.router.navigate(['/success-purchase']);
            }
            if (checkoutResponseTable) {
              localStorage.setItem('typeBuy', this.typeBuy);
              this.spinnerService.loadSpinner.next(false);
              window.location.href = checkoutResponseTable.url;
            }
          },
          error: (err) => {
            this.handleErrorCheckoutTable(err);
          }
        });
      }
    }
  }

  private handleErrorAccountStatus(err) {
    this.errorTitle = $localize`Error`;
    if (err.status === 404) {
      this.errorContent = $localize`Usuario invalido.`;
    } else {
      this.errorContent = $localize`Ha ocurrido un error, por favor volvé a intentarlo.`;
    }
    this.showErrorModal = true;
    this.spinnerService.loadSpinner.next(false);
  }

  private handleErrorCheckoutTable(err) {
    switch (err.status) {
      case 406:
        this.errorTitle = $localize`Tipo de combo o mesa no disponible`;
        this.errorContent = $localize`Lo sentimos, ese tipo de combo o mesa ya no se encuentra disponible.`;
        this.errorIcon = 'fa-thin fa-triangle-exclamation';
        break;
      case 418:
        this.errorTitle = $localize`Tipo de combo o mesa no disponible`;
        this.errorContent = $localize`Lo sentimos! La cantidad de combos o mesas disponibles es menor a la solicitada.`;
        this.errorIcon = 'fa-thin fa-triangle-exclamation';
        break;
      case 403:
        this.errorTitle = $localize`Error al procesar el pago`;
        this.errorContent = $localize`Lo sentimos, ha ocurrido un error procesando el pago, intente nuevamente más tarde o con otro medio de pago.`;
        this.errorIcon = 'fa-thin fa-triangle-exclamation';
        break;
      case 409:
        this.errorTitle = $localize`Ya sos parte de este evento`;
        this.errorContent = $localize`Lo vas a encontrar en la sección “Mis Eventos”, en la App.`;
        this.errorIcon = 'fa-thin fa-circle-info text-info';
        break;
      default:
        this.errorTitle = $localize`Error `;
        this.errorContent = $localize`Ha ocurrido un error, por favor volvé a intentarlo.`;
        this.errorIcon = 'fa-thin fa-triangle-exclamation';
        break;
    }
    this.showErrorModal = true;
    this.spinnerService.loadSpinner.next(false);
  }

  private handleErrorCheckoutAccess(err) {
    this.errorTitle = 'Error';
    switch (err.status) {
      case 406:
        this.errorTitle = $localize`Tipo de acceso no disponible`;
        this.errorContent = $localize`Lo sentimos, ese tipo de acceso ya no se encuentra disponible.`;
        this.errorIcon = 'fa-thin fa-triangle-exclamation';
        break;
      case 418:
        this.errorTitle = $localize`Tipo de acceso no disponible`;
        this.errorContent = $localize`Lo sentimos! La cantidad de accesos disponibles es menor a la solicitada.`;
        this.errorIcon = 'fa-thin fa-triangle-exclamation';
        break;
      case 403:
        this.errorTitle = $localize`Error al procesar el pago`;
        this.errorContent = $localize`Lo sentimos, ha ocurrido un error procesando el pago, intente nuevamente más tarde o con otro medio de pago.`;
        this.errorIcon = 'fa-thin fa-triangle-exclamation';
        break;
      case 409:
        this.errorTitle = $localize`Ya sos parte de este evento`;
        this.errorContent = $localize`Lo vas a encontrar en la sección “Mis Eventos”, en la App.`;
        this.errorIcon = 'fa-thin fa-circle-info text-info';
        break;
      default:
        this.errorTitle = $localize`Error`;
        this.errorContent = $localize`Ha ocurrido un error, por favor volvé a intentarlo.`;
        this.errorIcon = 'fa-thin fa-triangle-exclamation';
        break;
    }
    this.showErrorModal = true;
    this.spinnerService.loadSpinner.next(false);
  }

  private async handleAccountStatus(res: VerificationAccount) {
    if (res.blocked) {
      const modal = await this.createModalAction(
        'icon-warning.svg',
        $localize`Cuenta bloqueada`,
        $localize`Tu cuenta se encuentra bloqueada para operar. Si crees que se debe a un error, por favor comunicate con soporte.`,
        $localize`Aceptar`
      );
      const data = modal.onDidDismiss();
      if (data) {
        this.authService.logout();
      }
    } else {
      if (!res.documentVerified && !res.emailVerified) {
        // invalid DNI and EMAIL
        const modal = await this.createModalAction(
          'fa-thin fa-triangle-exclamation',
          $localize`Validá tu email, tu DNI y continuá con el pago.`,
          $localize`Seguí las instrucciones desde tu casilla de correos, y validá tu identidad a continuación.`,
          $localize`Aceptar`
        );
        const data = modal.onDidDismiss();
        if (data) {
          await this.router.navigate(['data-required']);
        }
      } else if (!res.documentVerified) {
        // invalid DNI
        const modal = await this.createModalAction(
          'invalid-dni.svg',
          $localize`Validá tu DNI y continuá con el pago.`,
          $localize`Para continuar con la compra validá tu identidad a continuación.`,
          $localize`Aceptar`
        );
        const data = modal.onDidDismiss();
        if (data) {
          await this.router.navigate(['data-required']);
        }
      } else {
        // invalid EMAIL
        this.errorAccountTitle = $localize`Validá tu email y continuá con el pago.`;
        this.errorAccountContent = $localize`Seguí las instrucciones desde tu casilla de correos, para activar tu cuenta.`;
        this.errorAccountImage = '';
        this.errorAccountIcon = 'fa-thin fa-triangle-exclamation';
        this.showNeedVerifiedAccount = true;
      }
      this.spinnerService.loadSpinner.next(false);

    }
  }

  private async createModalAction(icon: string, title: string, content: string, textBtn: string) {
    const modal = await this.modalCtrl.create({
      component: ModalActionComponent,
      props: {
        icon,
        title,
        content,
        textBtn
      },
    });
    await modal.present();
    return modal;
  }

  onActionToLogin() {
    if (this.code !== null && this.code !== '') {
      const promotionPersist: PromotionPersist = new PromotionPersist();
      promotionPersist.checkoutPage = true;
      promotionPersist.eventId = this.idEvent;
      promotionPersist.buyType = this.typeBuy;
      promotionPersist.promoCode = this.code;
      sessionStorage.setItem('promotionPersist', JSON.stringify(promotionPersist));
    } else if (this.idEvent !== null) {
      localStorage.setItem('lastDetailEvent', this.idEvent);
    }

    this.router.navigate(['/login']);
  }

  onNeedVerifiedAccount(e) {
    this.showNeedVerifiedAccount = false;
  }

  onActionErrorModal(e) {
    this.showErrorModal = false;
  }

  buyTicket() {
  }

  editPayment() {
    this.router.navigate(['/payment']);
  }

  getDataModalImage() {
    this.showModalActionLogin = false;
  }

  back() {
    this.location.back();
  }

  getDataPromoComponent(promotion: Promotion) {
    if (promotion) {
      if (this.typeBuy === 'A') {
        this.currentPayment.promoId = promotion.idPromotion;
      } else if (this.typeBuy === 'T') {
        this.currentPaymentTable.promoId = promotion.idPromotion;
      }
      this.promoDiscount = promotion.discount;
      this.promoCodeValid = promotion.discount > 0;
    } else {
      if (this.currentPayment) {
        this.currentPayment.promoId = null;
      }
      this.promoDiscount = null;
    }
  }

  getServicePrice() {
    let commissionBillboard = 0;
    const discount = this.getPromoDiscountAmount();
    let amount;
    if (this.typeBuy === 'A' && this.currentPayment) {
      commissionBillboard = Number.isSafeInteger(this.eventData.storeDataOnEvent.accessPercentage) ? this.eventData.storeDataOnEvent.accessPercentage : 10;
      amount = this.currentPayment.amountTotal;
    } else if (this.typeBuy === 'T' && this.currentPaymentTable) {
      commissionBillboard = Number.isSafeInteger(this.eventData.storeDataOnEvent.tablePercentage) ? this.eventData.storeDataOnEvent.tablePercentage : 10;
      amount = this.currentPaymentTable.amountTotal;
    }
    const priceWithDiscount = amount - discount;
    return (priceWithDiscount / 100) * commissionBillboard;
  }

  getFinalPrice() {
    let finalPrice;
    if (this.typeBuy === 'A' && this.currentPayment) {
      const discount = this.getPromoDiscountAmount();
      const servicePrice = this.getServicePrice();
      finalPrice = this.currentPayment.amountTotal - discount + servicePrice;
    } else if (this.typeBuy === 'T' && this.currentPaymentTable) {
      const discount = this.getPromoDiscountAmount();
      const servicePrice = this.getServicePrice();
      finalPrice = this.currentPaymentTable.amountTotal - discount + servicePrice;
    }
    return finalPrice;
  }

  getSubtotal() {
    let subtotal;
    if (this.typeBuy === 'A' && this.currentPayment) {
      subtotal = this.currentPayment.amountTotal;
    } else if (this.typeBuy === 'T' && this.currentPaymentTable) {
      subtotal = this.currentPaymentTable.amountTotal;
    }
    return subtotal;
  }

  getPromoDiscountAmount() {
    let totalWithDiscount;
    if (this.promoDiscount) {
      if (this.typeBuy === 'A' && this.currentPayment) {
        totalWithDiscount = (this.currentPayment.amountTotal / 100) * this.promoDiscount;
      } else if (this.typeBuy === 'T' && this.currentPaymentTable) {
        totalWithDiscount = (this.currentPaymentTable.amountTotal / 100) * this.promoDiscount;
      }
      return totalWithDiscount;
    } else {
      return 0;
    }
  }

  getUnderMaintenance() {
    this.firebaseRemoteConfigSrv.getBooleanValueByKey(environment.isUnderMaintenanceFirebaseKey)
    .then(result => {      
        if (result) {
          this.router.navigateByUrl('maintenance');
        }
    });
  }
}
