import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {DatePipe, registerLocaleData} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AngularFireModule} from '@angular/fire/compat';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {CheckboxModule} from 'primeng/checkbox';
import {FooterComponent} from './core/components/footer/footer.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {InputMaskModule} from 'primeng/inputmask';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {MaintenanceComponent} from './pages/maintenance/maintenance.component';
import {ModalController} from './controller/modal-controller';
import {NavbarComponent} from './core/components/navbar/navbar.component';

import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ScrollDirective} from './share/directives/scroll.directive';
import {ShareModule} from './share/share.module';
import {SpinnerComponent} from './share/components/spinner/spinner.component';
import {SwiperModule} from 'swiper/angular';
import {currentLocale} from 'src/locale/i18n';
import {environment} from 'src/environments/environment';
import {i18nInit} from '../locale/i18n';
import localeAr from '@angular/common/locales/es-AR';
import localeEn from '@angular/common/locales/en';
import {PagesModule} from './pages/pages.module';
import {ModalAccountUnverifiedComponent} from './share/components/modal-account-unverified/modal-account-unverified.component';
import {AngularFireRemoteConfigModule, DEFAULTS, SETTINGS, AngularFireRemoteConfig} from '@angular/fire/compat/remote-config';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {ToastModule} from 'primeng/toast';
import {DialogService, DynamicDialogModule} from 'primeng/dynamicdialog';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ClipboardModule} from '@angular/cdk/clipboard';

registerLocaleData(localeAr, 'es');
registerLocaleData(localeEn, 'en');

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    SpinnerComponent,
    MaintenanceComponent,
    ScrollDirective,
    ModalAccountUnverifiedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PagesModule,
    InputNumberModule,
    InputTextareaModule,
    InputTextModule,
    InputSwitchModule,
    InputMaskModule,
    ProgressSpinnerModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireRemoteConfigModule,
    SwiperModule,
    BrowserAnimationsModule,
    FormsModule,
    CheckboxModule,
    ShareModule,
    ReactiveFormsModule,
    ButtonModule,
    DialogModule,
    ToastModule,
    DynamicDialogModule,
    ClipboardModule
  ],
  providers: [
    DatePipe,
    ModalController,
    ConfirmationService,
    MessageService,
    DialogService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: currentLocale},
    {provide: APP_INITIALIZER, useFactory: () => i18nInit, multi: true},
    {      
      provide: SETTINGS, useValue: {fetchTimeoutMillis: 10000, minimumFetchIntervalMillis: 10000}
    }
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private remoteConfig: AngularFireRemoteConfig) {
    this.remoteConfig.fetchAndActivate();
  }
}
